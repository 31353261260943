<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages },

  data() {
    return {
      tenant: "",
      tournament_id: "",
      tournament_season: "",
      competition: "",
      tenant_text: "",
      competition_options: [],
      competition_type: "",
      active_season: null,
      primary: null,
      order: null,
      tenants_options: [],
      competition_type_options: ["cup", "league"], // Hardcoded options
      showModal: false,
      submitted: false,
      isLoading: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    competition: {
      required,
      maxLength: maxLength(8),
    },
    tenant: {
      required,
    },
    competition_type: {
      required,
    }
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },

    async getTenants() {
      try {
        const response = await Monitor.getAllTenants();
        this.tenants_options = response.data.data.map(item => ({
          value: item.id,
          tenant: item.tenant, // Store the tenant name separately
          text: `${item.tenant} - ${item.provider} (${item.team_type})`
        }));
      } catch (error) {
        console.error("Error fetching tenants:", error);
      }
    },


    async createTenantSetup() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      const tenantItem = this.tenants_options.find(item => item.value === this.tenant);

      if (!tenantItem) {
        this.failedmsg("Invalid tenant selected.");
        this.tryingToSubmit = false;
        return;
      }
      let formData = new FormData();
      formData.append("tenant_team_id", this.tenant);
      formData.append("tenant", tenantItem.tenant);
      formData.append("tournament_id", this.competition);
      formData.append("competition_type", this.competition_type);
      formData.append("active_season", this.active_season);
      formData.append("primary", this.primary);
      formData.append("order", this.order);

      try {
        const response = await Monitor.createTenantTournament(formData);
        if (response.error) {
          this.failedmsg(response.error);
        } else {
          this.successmsg(response.message);
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.refreshData();
      }
    },

    async getAllTournament() {
      try {
        const response = await Monitor.getAllTournament();
        if (response.data && Array.isArray(response.data.data)) {
          this.competition_options = response.data.data.map(item => ({
            value: item.tournament_id || 'N/A', // Default to 'N/A' if undefined
            text: `${item.competition_name || 'N/A'} - ${item.tournament_season || 'N/A'} (${item.provider || 'N/A'})`,
          }));
        } else {
          throw new Error('Unexpected response structure');
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.error = error.response.data.error ? error.response.data.error : "An error occurred";
        } else {
          this.error = error.message ? error.message : "An error occurred";
        }
        console.error("Error fetching tournaments:", this.error);
      }
    },


    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.tenant = "";
      this.competition = "";
      this.competition_type = "";
      this.active_season = null;
      this.primary = null;
      this.order = null;
      this.$v.$reset();
    },

    modalShown() {
      this.getTenants();
      this.getAllTournament();
    },
  },
};
</script>

<template>
  <b-modal @shown="modalShown" id="add_tenant_tournament" size="l" v-model="showModal" @hidden="resetForm" title="Tenant Setup" title-class="font-18">
    <form @submit.prevent="createTenantSetup">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">

              <b-form-group label="Tenant" label-for="formrow-tenant-input" class="mb-3">
                <b-form-select
                    v-model="tenant"
                    :options="tenants_options"
                    :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Tenant'" :validationName="$v.tenant"></validationMessages>
              </b-form-group>

              <b-form-group label="Competitions/Tournaments" label-for="formrow-competition-input" class="mb-3">
                <b-form-select
                    v-model="competition"
                    :options="competition_options"
                    :class="{ 'is-invalid': submitted && $v.competition.$error }"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Competitions/Tournaments'" :validationName="$v.competition"></validationMessages>
              </b-form-group>

              <b-form-group label="Competition Type" label-for="formrow-competition_type-input" class="mb-3">
                <b-form-select
                    v-model="competition_type"
                    :options="competition_type_options"
                    :class="{ 'is-invalid': submitted && $v.competition_type.$error }"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Competition Type'" :validationName="$v.competition_type"></validationMessages>
              </b-form-group>

              <b-form-group label="Active Season" label-for="formrow-active_season-input" class="mb-3">
                <b-form-select
                    v-model="active_season"
                    :options="[
                      { value: 1, text: '1' },
                      { value: 0, text: '0' }
                    ]"
                ></b-form-select>
              </b-form-group>

              <b-form-group label="Primary" label-for="formrow-primary-input" class="mb-3">
                <b-form-select
                    v-model="primary"
                    :options="[
                      { value: 1, text: '1' },
                      { value: 0, text: '0' }
                    ]"
                ></b-form-select>
              </b-form-group>

              <b-form-group label="Order" label-for="formrow-order-input" class="mb-3">
                <b-form-input v-model.trim="order" type="number"></b-form-input>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createTenantSetup" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
